import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'perfect-scrollbar';
import Tokens from './Tokens';
import Balance from './Balance';
import DepositandWithdraw from './DepositandWithdraw';
import { getSelectedAccount, WalletButton } from 'exnomy-wallet';
import styled from 'styled-components';

const mapStateToProps = state => {
  const selectedAccount = getSelectedAccount(state);
  const langJson = state.language.langJson;
  return {
    selectedAccount,
    langJson,
    isDarkMode: state.theme.isDarkMode
  };
};

const OPTIONS = [{ value: 'tokens', name: '' }];

class Wallet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccountID: OPTIONS[0].value
    };
  }

  render() {
    const { langJson, isDarkMode, selectedAccount } = this.props;
    const { BALANCE, TOKENS } = langJson;
    return (
      <>
        <NoticeSummary isDarkMode={isDarkMode}>
          <h4>Notice</h4>
          <p>
            The EXNOMY services were terminated on July 8, 2024. Asset withdrawals can be requested via email, and trading is no longer possible.
          </p>
          {/* <a href="/service-discontinuation">View more</a> */}
        </NoticeSummary>
        {
          selectedAccount ? <>
            <WrapBar dark={isDarkMode}>
              <div className="Bar2_title ">{BALANCE.TITLE}</div>
            </WrapBar>
            <div>
              <Balance />
            </div>
            <Tokens />
            <DepositandWithdraw />
          </> : <>
            <DummyBar dark={isDarkMode}>
              <WrapBar dark={isDarkMode}>
                <div className="Bar2_title ">{BALANCE.TITLE}</div>
              </WrapBar>
              <div className='loginBtnLyt'>
                <div className="connect">
                  <div className="text-connect">{TOKENS.LOGIN_INFO}</div>
                  <div className="center">
                    <WalletButton />
                  </div>
                </div>
              </div>
              <div className='walletLyt'>
                <Header1 dark={isDarkMode}>
                  <div className="detail-product">{TOKENS.TITLE}</div>
                </Header1>
                <Header dark={isDarkMode}>
                  <div className="detail-product">{TOKENS.PRODUCT}</div>
                  <div className="detail-symbol">{TOKENS.SYMBOL}</div>
                  <div className="detail-total">{TOKENS.TOTAL}</div>
                  <div className="detail-hold">{TOKENS.HOLD}</div>
                </Header>
                <div className="empty">
                  <span>{TOKENS.NODATA}</span>
                </div>
              </div>
            </DummyBar>
          </>
        }
      </>
    );
  }

  setRef(ref) {
    if (ref) {
      this.ps = new PerfectScrollbar(ref, {
        suppressScrollX: true,
        maxScrollbarLength: 20
      });
    }
  }
}

const WrapBar = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'};

  .Bar2_title {
    height: 55px;
    line-height: 55px;
    box-sizing: border-box;
    font-size: 18px;
    color: #6666ff;
    padding-left: 30px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const DummyBar = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: inline-block;
  box-sizing: border-box;

  .loginBtnLyt {
    height: 90px;
    line-height: 90px;
    box-sizing: border-box;
    font-size: 18px;
    border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'};
    border-top: none;
  }

  .connect {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 90px;
    text-align: center;
    .text-connect {
      width: 100%;
      margin: 0 auto;
      height: 32px;
      line-height: 32px;
      font-size: 13px;
      color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
    }
    
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .HydroSDK-toggleButton {
    outline: none;
    border: none;
    background-color: #525aee !important;
    border-color: transparent !important;
    border-style: none;
    border-radius: 0px;
    font-size: 16px;
    align-self: center;
    margin-left: 8px;
    padding: 0px !important;
    padding-right: 16px !important;
    color: white !important;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active,
    &:visited {
      box-shadow: none;
      border: transparent;
      outline: none !important;
    }
    @media (max-width: 1024px) {
      span {
        display: flex;
        height: 0px;
        align-items: center;
      }
    }
  }

  .text {
    width: 250px;
    margin: 0 auto;
    padding-left: 25px;
    height: 32px;
    line-height: 63px;
    font-size: 13px;
    color: #364958;
  }

  .walletLyt {
    min-height: 564px;
    box-sizing: border-box;
    font-size: 18px;
    margin-top: 16px;
    border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'};

    .empty {
      width: 100%;
      height: 372px;
      line-height: 428px;
      text-align: center;
      background: url(https://jeongfront.s3-ap-northeast-1.amazonaws.com/empty_icon.png) no-repeat center;
      span {
        top: 60%;
        font-size: 10.5px;
        color: #9e9e9e;
      }
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Header1 = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'};
  div {
    font-size: 12px;
    color: #364958;
    text-align: center;
  }
  .detail-product {
    font-size: 14px;
    color: #6666ff;
    width: 147px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'};
  div {
    font-size: 13px;
    color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
    text-align: center;
  }
  .detail-product {
    font-size: 13px;
    color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
    margin-left: 18px;
    padding-left: 30px;
    text-align: start;
    font-weight: bold;
    width: 25%;
  }
  .detail-symbol {
    width: 6%;
    font-size: 13px;
    font-weight: bold;
  }
  .detail-total {
    width: 24%;
    font-size: 13px;
    padding-left: 56px;
    text-align: center;
    font-weight: bold;
  }
  .detail-hold {
    width: 20%;
    font-size: 13px;
    padding-left: 28px;
    text-align: center;
    font-weight: bold;
  }
  .detail-action {
    width: 20%;
    padding-left: 8px;
    font-size: 13px;
    font-weight: bold;
  }
  .detail-availBalance {
    width: 25%;
    font-size: 13px;
    padding-left: 24px;
    text-align: center;
    font-weight: bold;
  }
  .detail-enable {
    width: 10%;
    font-size: 13px;
    text-align: end;
    color: #364958;
  }
`;

const NoticeSummary = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 20px;
  padding: 20px;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#2a2a2a' : '#f9fbfd')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
  border: 1px solid ${({ isDarkMode }) => (isDarkMode ? '#52576f' : '#e2e6fa')};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  a {
    font-size: 1rem;
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1024px) {
    padding: 15px;

    h4 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
    }

    a {
      font-size: 0.9rem;
    }
  }
`;

export default connect(mapStateToProps)(Wallet);
