import React from 'react';
import { connect } from 'react-redux';
import { loadTokens } from '../../actions/account';
import { toUnitAmount, isTokenApproved } from '../../lib/utils';
import { stateUtils } from '../../selectors/account';
import { getSelectedAccount } from 'exnomy-wallet';
import { BigNumber } from 'bignumber.js';
import styled from 'styled-components';
import { toDoOperation } from '../../actions/wallet';
import TokensConfig from '../../assets/js/tokens';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import 'react-toastify/dist/ReactToastify.css';
import onClickOutside from 'react-onclickoutside';
import { thousands_separators } from '../../lib/helper'
import MediaQuery from 'react-responsive';
import { NavLink } from 'react-router-dom';
const cryptocurrencies = require('cryptocurrencies');

const mapStateToProps = state => {
  const selectedAccountID = state.WalletReducer.get('selectedAccountID');
  const selectedAccount = getSelectedAccount(state);
  const accountAddress = selectedAccount ? selectedAccount.get('address') : null;
  const lang = state.language.lang;
  const selectedToken = state.wallet.Token;
  const langJson = state.language.langJson;
  return {
    currentMarket: state.market.getIn(['markets', 'currentMarket']),
    markets: state.market.getIn(['markets', 'data']),
    tokensInfo: stateUtils.getTokensInfo(state, accountAddress),
    accountAddress,
    ethBalance: toUnitAmount(state.WalletReducer.getIn(['accounts', selectedAccountID, 'balance']), 18),
    isLoggedIn: state.account.getIn(['isLoggedIn', accountAddress]),
    lang,
    langJson,
    selectedToken,
    isDarkMode: state.theme.isDarkMode
  };
};

class Tokens extends React.PureComponent {
  notify = TOAST => {
    toast.error(TOAST.ENABLE_TOKEN, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify1 = TOAST => {
    toast.error(TOAST.TRANSFER_IS_DISABLED, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  notify2 = TOAST => {
    toast.error(TOAST.TRADE_IS_DISABLED, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      className: css({
        textAlign: 'center',
        width: '280px',
        fontSize: '15px'
      })
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      isShowingFirstItem: true,
      open: '',
      isTransfer:true
    };
  }

  componentDidMount() {
    const { accountAddress, dispatch, tokensInfo } = this.props;
    if (accountAddress) {
      dispatch(loadTokens());
    }

    if (tokensInfo) {
      this.highlightDefaultCoinToBeSelected()
    }
  }

  componentDidUpdate(prevProps) {
    const { accountAddress, dispatch, tokensInfo } = this.props;
    if (accountAddress && accountAddress !== prevProps.accountAddress) {
      dispatch(loadTokens());
    }

    if (tokensInfo && tokensInfo !== prevProps.tokensInfo) {
      this.highlightDefaultCoinToBeSelected()
    }

  }

  // Method automatically executed by the library.
  handleClickOutside() {
    if (this.state.open !== '') this.setState({ open: '' });
  }

  highlightDefaultCoinToBeSelected() {
    const { dispatch, tokensInfo } = this.props;
    if (!localStorage.getItem("Wallet_CLICKED")) {
      localStorage.setItem("Wallet_CLICKED", "BTC")
    }
    {
      tokensInfo.toArray().filter(([token, info]) => localStorage.getItem("Wallet_CLICKED") === token).map(([token, info]) => {
        const { address, balance, decimals, lockedBalance } = info.toJS();
        const totalBalance = new BigNumber(balance.toString()) / Math.pow(10, decimals);
        const lockedBal = new BigNumber(lockedBalance.toString()) / Math.pow(10, decimals);
        const availableBalance = toUnitAmount(BigNumber.max(balance.minus(lockedBalance), '0'), decimals).toFixed(8);
        console.log("token" + token)
        if (token === localStorage.getItem("Wallet_CLICKED") && address.length > 0) {
          if (TokensConfig.BITGO[token]) {
            dispatch(
              toDoOperation(
                'TRADE',
                token,
                availableBalance,
                totalBalance,
                lockedBal,
                address,
                'BITGO',
                decimals
              )
            );
            localStorage.setItem("Wallet_CLICKED", token)
          } else if (TokensConfig.KLAY[token]) {
            dispatch(
              toDoOperation(
                'TRADE',
                token,
                availableBalance,
                totalBalance,
                lockedBal,
                address,
                'KLAY',
                decimals
              )
            );
            localStorage.setItem("Wallet_CLICKED", token)
          } else {
            dispatch(
              toDoOperation(
                'TRADE',
                token,
                availableBalance,
                totalBalance,
                lockedBal,
                address,
                'ERC20',
                decimals
              )
            );
            localStorage.setItem("Wallet_CLICKED", token)
          }
        }
        return null;
      })
    }
  }

  render() {
    const { dispatch, tokensInfo, langJson, isDarkMode } = this.props;
    const { TOKENS } = langJson;
    return (
      <Wrap dark={isDarkMode}>
        <Header1 dark={isDarkMode}>
          <div className="detail-product">{TOKENS.TITLE}</div>
        </Header1>
        <Header dark={isDarkMode}>
          <div className="detail-product">{TOKENS.PRODUCT}</div>
          <MediaQuery minWidth={1024}> <div className="detail-symbol">{TOKENS.SYMBOL}</div></MediaQuery>
          <div className="detail-total">{TOKENS.TOTAL}</div>
          <div className="detail-hold">{TOKENS.HOLD}</div>
          <div className="detail-action">{TOKENS.WALLET_ACTIONS}</div>
        </Header>
        {/* this function iterates over the whole map */}
        {tokensInfo
          .toArray()
          .sort(function (token1, token2) {
            let token1Mapped = cryptocurrencies[token1[0]]
              ? cryptocurrencies[token1[0]].toLowerCase()
              : token1[0].toLowerCase();
            let token2Mapped = cryptocurrencies[token2[0]]
              ? cryptocurrencies[token2[0]].toLowerCase()
              : token2[0].toLowerCase();
            if (token1Mapped < token2Mapped) {
              return -1;
            }
            if (token1Mapped > token2Mapped) {
              return 1;
            }
            return 0;
          })
          .map(([token, info]) => {
            const { address, balance, decimals, lockedBalance, allowance, holdBalance } = info.toJS();
            const numberOfDecimalsToRoundOff = TokensConfig.DECIMALS[token].UI_DECIMAL;
            const totalBalance = new BigNumber(new BigNumber(balance.toString()).plus(new BigNumber(holdBalance.toString())) / Math.pow(10, decimals)).toFixed(
              numberOfDecimalsToRoundOff
            );
            const lockedBal = new BigNumber(new BigNumber(lockedBalance.toString()).plus(new BigNumber(holdBalance.toString())) / Math.pow(10, decimals)).toFixed(
              numberOfDecimalsToRoundOff
            );
            const availableBalance = toUnitAmount(BigNumber.max(balance.minus(lockedBalance), '0'), decimals).toFixed(
              numberOfDecimalsToRoundOff
            );
            let tokenURL = TokensConfig.BITGO[token]
              ? process.env.PUBLIC_URL + '/images/crypto_icons/' + token.toLowerCase() + '.svg'
              : TokensConfig.KLAY[token]
                ? process.env.PUBLIC_URL + '/images/klay.png'
                : process.env.PUBLIC_URL + '/images/garbage.png';
            const isApproved = TokensConfig.ERC20[token] ? isTokenApproved(allowance) : true;
            let TokenName = cryptocurrencies[token] ? cryptocurrencies[token] : token === 'KLAY' ? 'Klaytn' : token;

            if (token === 'LNC')
              TokenName = 'Linker'

            if (token === 'BSV') tokenURL = process.env.PUBLIC_URL + '/images/bsv.png';

            if (token === 'LNC') tokenURL = process.env.PUBLIC_URL + '/images/lnc.png';

            return (
              <Wrap1 dark={isDarkMode} isTransfer={this.state.isTransfer}
                key={token}
                isApproved={isApproved}
                isEnabledDeposit={TokensConfig.DEPOSIT[token]}
                isEnabledWithdraw={TokensConfig.WITHDRAW[token]}
                onClick={() => {
                  if (TokensConfig.BITGO[token]) {
                    dispatch(
                      toDoOperation(
                        'TRADE',
                        token,
                        availableBalance,
                        totalBalance,
                        lockedBal,
                        address,
                        'BITGO',
                        decimals
                      )
                    );
                  } else if (TokensConfig.KLAY[token]) {
                    dispatch(
                      toDoOperation(
                        'TRADE',
                        token,
                        availableBalance,
                        totalBalance,
                        lockedBal,
                        address,
                        'KLAY',
                        decimals
                      )
                    );
                  } else {
                    dispatch(
                      toDoOperation(
                        'TRADE',
                        token,
                        availableBalance,
                        totalBalance,
                        lockedBal,
                        address,
                        'ERC20',
                        decimals
                      )
                    );
                  }
                  localStorage.setItem("Wallet_CLICKED", token)
                }}>
                <div className="detail-item-name">
                  <img src={tokenURL} alt="" width="18" height="18" style={{ marginRight: '4px', marginBottom: '2px' }} />
                  {TokenName}
                </div>
                <MediaQuery minWidth={1024}>  <div className="detail-item-symbol">
                  {TokensConfig.CONSTANT.APPEND}
                  {token}
                </div></MediaQuery>
                <div className="detail-item-total">{
                  Number(totalBalance) === 0 ? 0 :
                    new BigNumber(totalBalance).gte(new BigNumber('1000')) ? thousands_separators(new BigNumber(totalBalance).toFixed(0)) :
                      new BigNumber(totalBalance).gte(new BigNumber('100')) ? new BigNumber(totalBalance).toFixed(1) :
                        new BigNumber(totalBalance).gte(new BigNumber('1')) ? new BigNumber(totalBalance).toFixed(3) :
                          totalBalance
                }
                </div>
                {/* <div className="detail-item-total">{totalBalance}</div> */}
                <div className="detail-item-hold">{
                  Number(lockedBal) === 0 ? 0 :
                    new BigNumber(lockedBal).gte(new BigNumber('1000')) ? thousands_separators(new BigNumber(lockedBal).toFixed(0)) :
                      new BigNumber(lockedBal).gte(new BigNumber('100')) ? new BigNumber(lockedBal).toFixed(1) :
                        new BigNumber(lockedBal).gte(new BigNumber('1')) ? new BigNumber(lockedBal).toFixed(3) :
                          lockedBal
                }
                </div>
                {/* <div className="detail-item-hold">{lockedBal}</div> */}
                <div className="action-content">
                  {this.renderActions(token, availableBalance, totalBalance, lockedBal, address, decimals)}
                </div>
              </Wrap1>
            );
          })}
      </Wrap>
    );
  }

  showPopUp(token) {
    const { markets } = this.props;
    return (
      <Wrap2 >
        {markets.map((market, k) => {
          if (market.baseToken === token) {
            return (
              <div
              key={k}
                onClick={() => {
                  window.localStorage.setItem(`currentMarket`, JSON.stringify(market));
                }}>
                   <NavLink
                  to={'/exchange'}>
                  {market.quoteToken}
                </NavLink>
               
              </div>
            );
          } else {
            return null;
          }
        })}
      </Wrap2>
    );
  }

  renderActions(token, availableBalance, totalBalance, lockedBal, address, decimals) {
    const { dispatch, langJson, selectedToken } = this.props;
    const { TOKENS, TOAST } = langJson;
    if (TokensConfig.BITGO[token]) {
      return (
        <>
          <div
            className="detail-item-transfer"
            onClick={() => {
              dispatch(
                toDoOperation('TRADE', token, availableBalance, totalBalance, lockedBal, address, 'BITGO', decimals)
              );
            }}>
            <div className={(selectedToken === token  && this.state.isTransfer)? 'selected enable' : 'enable'} onClick={()=>this.setState({isTransfer:true})}>{TOKENS.TRANSFER}</div>
          </div>
          {/* <div
            className="detail-item-trade"
            onClick={() => {
              if (TokensConfig.TRADE[token] === '1') {
                this.setState({ open: token });
              } else {
                this.notify2(TOAST);
              }
            }}>
            <div className={(selectedToken === token  && !this.state.isTransfer) ? 'selected' : 'trade-btn'} onClick={()=>this.setState({isTransfer:false})}>{TOKENS.TRADE}</div>
            {token === this.state.open ? this.showPopUp(token) : null}
          </div> */}
        </>
      );
    } else if (TokensConfig.KLAY[token]) {
      return (
        <>
          <div
            className="detail-item-transfer"
            onClick={() => {
              dispatch(
                toDoOperation('TRADE', token, availableBalance, totalBalance, lockedBal, address, 'KLAY', decimals)
              );
            }}>
            <div className={(selectedToken === token  && this.state.isTransfer) ? 'selected enable' : 'enable'}>{TOKENS.TRANSFER}</div>
          </div>
          {/* <div
            className="detail-item-trade"
            onClick={() => {
              if (TokensConfig.TRADE[token] === '1') {
                this.setState({ open: token });
              } else {
                this.notify2(TOAST);
              }
            }}>
            <div className={(selectedToken === token  && !this.state.isTransfer) ? 'selected' : 'trade-btn'}>{TOKENS.TRADE}</div>
            {token === this.state.open ? this.showPopUp(token) : null}
          </div> */}
        </>
      );
    } else {
      return (
        <>
          <div
            className="detail-item-transfer"
            onClick={() => {
              dispatch(
                toDoOperation('TRADE', token, availableBalance, totalBalance, lockedBal, address, 'ERC20', decimals)
              );
            }}>
            <div className={(selectedToken === token  && this.state.isTransfer) ? 'selected' : 'enable'}>{TOKENS.TRANSFER}</div>
          </div>
          {/* <div
            className="detail-item-trade"
            onClick={() => {
              if (TokensConfig.TRADE[token] === '1') {
                this.setState({ open: token });
              } else {
                this.notify2(TOAST);
              }
            }}>
            <div className={(selectedToken === token  && !this.state.isTransfer) ? 'selected' : 'trade-btn'}>{TOKENS.TRADE}</div>
            {token === this.state.open ? this.showPopUp(token) : null}
          </div> */}
        </>
      );
    }
  }

  // renderEnable(token, isApproved, decimals, address) {
  //   if (address === '') address = '0x0000000000000000000000000000000000000000';
  //   const { dispatch } = this.props;
  //   if (TokensConfig.ERC20[token]) {
  //     return (
  //       <>
  //         <div className="custom-control custom-switch">
  //           <input
  //             type="checkbox"
  //             className="custom-control-input"
  //             id={address}
  //             checked={isApproved}
  //             onChange={() => {
  //               if (isApproved) {
  //                 dispatch(disable(address, token, decimals));
  //               } else {
  //                 dispatch(enable(address, token, decimals));
  //               }
  //             }}
  //           />
  //           <label className="custom-control-label" htmlFor={address} />
  //         </div>
  //       </>
  //     );
  //   } else {
  //     return null;
  //   }
  // }
}

const Wrap = styled.div`
  margin-top: 10px;
  box-sizing: border-box;
  width: 740px;
  min-height: 864px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
   @media (max-width: 1024px) {
    width:100%;
    min-height: 100%;
  }
`;
const Header1 = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  div {
    font-size: 12px;
    color: #364958;
    text-align: center;
  }
  .detail-product {
    font-size: 14px;
    color: #6666ff;
    width: 147px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  div {
    font-size: 13px;
    color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
    text-align: center;
  }
  .detail-product {
    font-size: 13px;
    text-align: center;
    color: ${({ dark }) => (dark ? '#8d94af' : '#676767')};
    font-weight: bold;
    width: 20%;
    @media (max-width: 1024px) {
      margin-left: 10%;
    }
  }
  .detail-symbol {
    width: 15%;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
  }
  .detail-total {
    width: 20%;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
  }
  .detail-hold {
    width: 20%;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
  }
  .detail-action {
    width: 25%;
    font-size: 13px;
    font-weight: bold;
  }
  // .detail-availBalance {
  //   width: 25%;
  //   font-size: 13px;
  //   padding-left: 24px;
  //   text-align: center;
  //   font-weight: bold;
  // }
  // .detail-enable {
  //   width: 10%;
  //   font-size: 13px;
  //   text-align: end;
  //   color: #364958;
  // }
`;

const Wrap1 = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid ${({ dark }) => dark ? '#52576f' : '#e2e6fa'}
  
  .detail-item-name {
    width: 20%;
    @media (max-width: 1024px) {
      width:30%;
      font-size: 0.8rem;
    }
    padding-left: 24px;
    font-size: 13px;
    text-align: start;
    color: ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
  }
  .detail-item-symbol {
    width: 15%;
    text-align: center;
    font-size: 13px;
    color: ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
  }
  .detail-item-total {
    color: ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
    text-align: center;
    font-size: 13px;
    width: 20%;
  }
  .detail-item-hold {
    color: ${({ dark }) => (dark ? '#8d94af' : '#1a1a1a')};
    text-align: center;
    font-size: 13px;
    width: 20%;
  }
  .action-content {
    width: 25%;
    display: inline;
    display: flex;
    font-size: 13px;
    flex-direction: row;
    .detail-item-transfer {
      width: 100%;
      cursor: pointer;
      text-align: center;

      .enable {
        color: #abb4e5;
        &:hover {
          color: #6666ff;
        }
      }

      .disable {
        color: #abb4e5;
        cursor: default;
      }
      .selected {
        color: #6666ff;
      }
    }
    .detail-item-trade {
      position: relative;
      display: flex;
      width: 50%;
      padding-left: 24px;
      cursor: pointer;
      text-align: center;

      div {
        color: #abb4e5;
        &:hover {
          color: #6666ff;
        }
      }
      div.active {
        color: #6666ff;
      }

      .selected {
        color: #6666ff;
      }
    }
  }
  // .detail-item-available {
  //   font-size: 12px;
  //   color: #1a1a1a;
  //   text-align: center;
  //   text-indent: 50px;
  //   width: 30%;
  // }
  // .action-enable-content {
  //   width: 20%;
  //   //align-items: end;
  // }
`;

const Wrap2 = styled.div`
  z-index: 5;
  position: absolute;
  top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 65px; */
  width: 60px;
  background: white;
  border: solid 1px #f2f4fd;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #364958;
    height: 32px;
    width: 100%;

    border-bottom: solid 1px #f2f4fd;
    &:nth-last-child(1) {
      border-bottom: none;
    }
    &:hover {
      background: #f2f4fd;
    }
  }
`;

export default connect(mapStateToProps)(onClickOutside(Tokens));
