import React from 'react';
import styled from 'styled-components';
import cookie from 'react-cookies';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  const langJson = state.language.langJson;
  return {
    langJson
  };
};

class Popup extends React.PureComponent {
  state = {
    txtClose: false,
    imgClose: false,
    txtCookie: true,
    imgCookie: false,
    imgUrl: 'https://jeongfront.s3-ap-northeast-1.amazonaws.com/notice.jpg',
    imgLinkData: 'https://link.medium.com/zG2pamdjt9'
  };

  closeImgPop = e => {
    e.preventDefault();
    if (this.state.imgCookie) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 1);
      cookie.save('imgPop', 'hide', { expires });
    }
    if (this.state.txtClose) {
      document.querySelector('#eventPopupWrap').remove();
    }
    this.setState({ imgClose: true });
  };

  closeTxtPop = () => {
    if (this.state.imgClose) {
      document.querySelector('#eventPopupWrap').remove();
    }
    this.setState({ txtClose: true });
  };

  componentDidMount() {
    if (this.state.txtClose || this.state.imgClose) {
      document.querySelector('#eventPopupWrap').remove();
    }

    const hidePop = cookie.load('imgPop');
    if (hidePop) this.setState({ imgClose: true });
  }

  render() {
    const { langJson } = this.props;
    const { POP_UP } = langJson;
    return (
      <EventPopup id="eventPopupWrap">
        <div id="eventPopup">
          {this.state.imgClose ? (
            <div></div>
          ) : (
            <div className="graphic-mode">
              <div className="img-body">
                <a href={this.state.imgLinkData} target="_blank" rel="noopener noreferrer">
                  <img src={this.state.imgUrl} alt="notice" />
                </a>
              </div>
              <div className="footer-wrap">
                <div className="graphic-footer">
                  <label htmlFor="today">
                    <input type="checkbox" id="today" onChange={e => this.setState({ imgCookie: true })} />
                    <span>{POP_UP.SHOW_ONCE_A_DAY}</span>
                  </label>
                  <p onClick={this.closeImgPop}>{POP_UP.CLOSE}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </EventPopup>
    );
  }
}

const EventPopup = styled.div`
  #eventPopup {
    width: 100%;
    position: fixed;
    top: 15%;
    z-index: 100;
    .graphic-mode {
      position: relative;
      width: 450px;
      margin: 0 auto;
      .img-body {
        position: relative;
        width: 450px;
        height: 450px;
        img {
          width: 100%;
        }
        .cls {
          display: block;
          position: absolute;
          right: 10px;
          top: 10px;
          width: 31px;
          height: 32px;
          overflow: hidden;
          font: normal 0/0 verdana;
          text-indent: 1000px;
          cursor: pointer;
        }
        .event1 {
          position: absolute;
          width: 201px;
          height: 127px;
          display: block;
          left: 38px;
          top: 147px;
        }
        .event2 {
          position: absolute;
          width: 201px;
          height: 127px;
          display: block;
          left: 272px;
          top: 147px;
        }
      }
      .footer-wrap {
        width: 100%;
        background: #fff;
      }
      .graphic-footer {
        height: 32px;
        box-sizing: border-box;
        margin: 0 20px;
        background: #fff;
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        place-content: center space-between;
        align-items: center;
        justify-content: space-between;
        label {
          cursor: pointer;
          color: #1a1a1a;
          font-size: 12px;
          input {
            display: inline-block;
            margin: 0 4px 0 0;
          }
        }
        p {
          font-size: 12px;
          cursor: pointer;
          font-weight: bold;
          color: #525aee;
        }
      }
    }
  }

  #eventPopup .img_wrap,
  #eventPopup .img_wrap img {
    width: 100%;
  }

  #eventPopup .btn_wrap {
    width: 100%;
    overflow: hidden;
    padding: 5px 10px;
    box-sizing: border-box;
  }

  #eventPopup .btn_wrap label {
    float: left;
    line-height: 30px;
    cursor: pointer;
  }

  #eventPopup .btn_wrap label input {
    margin-right: 3px;
  }

  #eventPopup .btn_wrap button {
    float: right;
  }

  .close-popup {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    #eventPopup {
      width: 100%;
      left: 10%;
      top: 15%;
    }
    .txt-mode {
      left: 0 !important;
      width: 100% !important;
      padding: 0 !important;
      background: #fff !important;
      .txt-footer {
        background: #fff;
      }
    }
    .graphic-mode {
      left: 0;
      width: 100%;
      .img-body {
        text-align: center;
        width: 288px !important;
        height: 288px !important;
        img {
          width: 100%;
        }
        .cls {
          width: 28px !important;
          height: 28px !important;
          top: 0 !important;
          right: 0 !important;
        }
        .go-link {
          left: unset !important;
          right: 56px !important;
          top: 164px !important;
        }
        .event1 {
          left: 7% !important;
          top: 100px !important;
          width: 40% !important;
          height: 100px !important;
        }
        .event2 {
          left: 55% !important;
          top: 100px !important;
          width: 40% !important;
          height: 100px !important;
        }
      }
    }
    .graphic-footer {
      height: 32px;
      width: 244px !important;
      box-sizing: border-box;
      margin: 0 20px;
      background: #fff;
      padding-top: 8px;
      display: flex;
      flex-direction: row;
      place-content: center space-between;
      align-items: center;
      justify-content: space-between;
      label {
        cursor: pointer;
        color: #1a1a1a;
        font-size: 12px;
        input {
          display: inline-block;
          margin: 0 4px 0 0;
        }
      }
      p {
        font-size: 12px;
        cursor: pointer;
        font-weight: bold;
        color: #525aee;
      }
    }
  }
`;

export default connect(mapStateToProps)(Popup);
