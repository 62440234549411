import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const ReactNotificationComponent = ({ title, body }) => {
    toast.info(<Display />);
    function Display() {
        return (
            <div>
                <h4>{title}</h4>
                <p>{body}</p>
            </div>
        );
    }
    return (
        <ToastContainer />
    );
};

export default ReactNotificationComponent;